import { render, staticRenderFns } from "./CondominiosView.vue?vue&type=template&id=b7aba4fe&scoped=true"
import script from "./CondominiosView.vue?vue&type=script&lang=js"
export * from "./CondominiosView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7aba4fe",
  null
  
)

export default component.exports