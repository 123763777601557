<template>
  <base-view title="Desarrollos" icon="home">
    <div>
      <check-authorization :requiresAuthorizations="['indice condominios']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Proyectos
            <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
              <!-- Form -->
              <check-authorization
                :requiresAuthorizations="['crear condominios']"
                :overrideIf="$store.getters['condominioModule/isEditingResource']"
              >
                <condominio-form
                  :value="showForm || isEditingResource"
                  @input="showForm = $event"
                />
              </check-authorization>
              <search-bar
                placeholder="Buscar por nombre o empresa"
                @filter="(filter) => this.filter = filter"></search-bar>
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <condominio-list @on-got-item="showForm=true" :filter="filter"></condominio-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import CondominioForm from '@/components/Catalogos/Condominios/CondominioForm'
import CondominioList from '@/components/Catalogos/Condominios/CondominioList'
import { mapActions, mapGetters } from 'vuex'
import SearchBar from '../../components/Common/Views/SearchBar.vue'

const STORE_MODULE = 'condominioModule'

export default {
  name: 'CondominiosView',

  components: {
    CondominioForm,
    CondominioList,
    SearchBar
  },

  data () {
    return {
      hideButton: false,
      showForm: false,
      filter: ''
    }
  },

  computed: mapGetters(STORE_MODULE, ['isEditingResource']),

  methods: {
    ...mapActions(STORE_MODULE, ['getResources'])
  }
}
</script>

<style scoped>

</style>
